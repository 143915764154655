<template>

        <!-- Modal -->
        <div class="modal fade"  :class="{ show: false, 'd-block': false }"  id="churchModal"    aria-labelledby="churchModalLabel" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="max-height:30%; max-width: 80%;" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="churchModalLabel" v-if="church_view_type=='add'">Add Church</h5>
                <h5 class="modal-title" id="churchModalLabel" v-else-if="church_view_type=='edit'">Edit Church</h5>
                <h5 class="modal-title" id="churchModalLabel" v-else-if="church_view_type=='view'">View Church</h5>
                <button type="button" class="btn-close" :data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#churchModal" aria-label="Close"></button>
            </div>
              <div v-if="this.church_view_type=='add' || this.church_view_type=='edit'">
              <form class="register-form" @submit.prevent="reg()">
            <div class="modal-body">

                <div class="card shadow-sm" id="stylized">
                   
                                <!-- <div class="card-body"> -->


                    <div class="card-body">
                      <h1 v-if="church_view_type == 'add'">Add Church</h1>
                      <h1 v-else-if="church_view_type == 'edit'">Edit Church</h1>
                      <br/>
                      <p></p>
        
                  <span>
                  
           




                      <div class="row" style="margin-bottom:10px" v-if="app_portal=='MAIN'">

                                    <div class="col">
                                <label><span class="text_error">*</span>Denomination</label>
                             <div class="input-group" style="width:100%">
                          
                          <!-- <select
                            class="form-control country"
                            :class="{ form_error: countryErr }"
                            ref="count"
                            @change="countryVal($event.target.value)"
                            v-model.trim="formData.country_id">
                            <option value="">--Choose a Country--</option>
                            <option v-for="cot in getCountries" :key="cot.id" :value="cot.id">
                              {{ cot.country }}
                            </option>
                          </select> -->
                          <!-- <Select2 v-model="formData.country_id" :options="getCountries"  :settings="{ placeholder: 'Specifies the placeholder through settings', width: '50%', ajax: ajax }" @change="countryVal($event.target.value)"  /> -->
                         <v-select :options="getDenominations"   class="form-control select1" v-model="formData.denomination_id"  :reduce="denomination => denomination.id" label="denomination" @option:selected="denominationVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: denominationErr }"
                            v-if="denominationErr"
                          >
                            {{ denominationErr }}
                          </div>

                      </div>


                      </div>





               <div class="row">


                       <div class="col" v-if="app_portal=='MAIN' || app_portal=='DENOMINATION'">
                        <label><span class="text_error">*</span>Church</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: churchErr}"
                            placeholder="Name Of Church"
                            @blur="churchVal($event.target.value)" 
                            v-model.trim="formData.church"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: churchErr  }"
                           v-if="churchErr">
                          {{ churchErr }} 
                          </div>
                      </div>






                            <div class="col">
                          <label><span class="text_error">*</span>Contact</label><br/>
                        <div class="input-group">
                          
                          <!-- <input
                            type="text"
                            class="form-control"
                            id="telephone"
                            :class="{ form_error: contactErr }"
                            placeholder="Contact"
                             @blur="contactVal($event.target.value)"
                            v-model.trim.lazy="formData.contact"
                          /> -->
                          <vue-tel-input v-model.lazy="formData.contact" @blur="contactVal" ></vue-tel-input>
                          
                           

                        </div>
                        <div
                            :class="{ text_error: contactErr }"
                            v-if="contactErr"
                          >
                            {{ contactErr }}
                          </div><br/>

                      </div>






                       <div class="col">
                        <label>Email</label>
                        <div class="input-group">
                          

                          <input
                            type="email"
                            class="form-control"
                            :class="{ form_error: emailErr}"
                            placeholder="Email"
                            @blur="emailVal($event.target.value)" 
                            v-model.trim="formData.email"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: emailErr  }"
                           v-if="emailErr">
                          {{ emailErr }} 
                          </div>
                      </div>




                   </div>





               <div class="row">




                     <div class="col">
                        <label><span class="text_error">*</span>Pastor's Name</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: pastor_nameErr}"
                            placeholder="Fullname Of Pastor"
                            @blur="pastor_nameVal($event.target.value)" 
                            v-model.trim="formData.pastor_name"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: pastor_nameErr  }"
                           v-if="pastor_nameErr">
                          {{ pastor_nameErr }} 
                          </div>
                      </div>




                     <div class="col">
                              <label><span class="text_error">*</span>Is Active</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: is_activeErr}"
                             @change="is_activeVal($event.target.value)"
                            v-model.trim="formData.is_active">
                            <option value="">--Choose An Option--</option>
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: is_activeErr}"
                            v-if="is_activeErr"
                          >
                            {{ is_activeErr }}
                          </div>
                      </div>

         



                     




                    </div>



                  </span>
                    </div>



                    

                                </div>
                
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" v-if="church_view_type=='add'">Submit</button>
                <button type="submit" class="btn btn-primary" v-if="church_view_type=='edit'">Update</button>
                <button type="button" class="btn btn-danger" @click="remove_all" v-if="church_view_type=='add'">Clear All</button>
                <button type="button" class="btn btn-secondary" ref="church_modal_ref" data-bs-dismiss="modal" v-if="church_view_type=='edit'">Close</button>
            </div>

                  </form>
                  </div>


                 <div v-else>
                      <div class="modal-body">
                        <div class="table-responsive table-responsive-data2">
                        <table class="table table-hover table-striped">
                          
                              <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Value</th>
                                </tr>
                                </thead>


                                <tbody>
                                <tr>
                                <td>Denomination</td>
                                <td>{{formData.denomination}}</td>
                                </tr>

                                <tr>
                                <td>Church</td>
                                <td>{{formData.church}}</td>
                                </tr>


                                <tr>
                                <td>Contact</td>
                                <td>{{formData.contact}}</td>
                                </tr>


                                <tr>
                                <td>Contact</td>
                                <td>{{formData.email}}</td>
                                </tr>

                                <tr>
                                <td>Pastor's Name</td>
                                <td>{{formData.pastor_name}}</td>
                                </tr>

                               <tr>
                                <td>Is Active</td>
                                <td>{{formData.is_active}}</td>
                                </tr>




                 
                              </tbody>
                                  </table>









                                                                

                    
                        </div>

                      </div>

                 </div>


            </div>
        </div>


        
        </div>
</template>

<script>
 import axios from 'axios';
import FieldValidateService from "@/services/FieldValidateService";
import notificationBox from "@/services/notificationBox";
//import SpinnerComp from "@/components/SpinnerComp.vue";
import { mapActions, mapMutations, mapState } from 'vuex';
import { DEPENDENT_LINK_REG, EDIT_DEPENDENT_LINK_REG, LOADING, RESET_DEPENDENT_SEARCH_RESULT, SEARCH_DEPENDENT } from '@/store/storeconstants';
//import VueMultiselect from 'vue-multiselect'
//import Select2 from 'vue3-select2-component';
import vSelect from 'vue-select'

    export default {

        name:'ChurchModals',
        props:{
            church_view_type:{
                required:true,
            },

            church_data:{
                required:true,
            }
        },


           mounted(){

              // const input = document.querySelector("#telephone");
              // console.log(input);
              // intlTelInput(input,{
              //   // any initialisation options go here
              //       preferredCountries: ["gh", "us", "ca"],
                
                  
              // });

             //   console.log(localStorage.getItem("token"));

           },

        async created() {

       

              try{
       let response = await axios.get("getDenominations");
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log(this.getCountries);
       
       
       
      
    }catch(error){
        console.log(error);
    }
  

   this.getPortalData();



  
 

        },




         data: function () {
    return {
      //userModal: null,
      link_val:"",
      app_portal: "",
      loading:false,
      errorMess: "",
      succMess:"",
      clear_user: true,
      clear_all: true,
      errorClient:[],
      errorServers:[],
      getDenominations:[],
       modal:"modal",


   
      

      formData: {
        portal:"",
        denomination_id:"",
        denomination: "",
        church_id: "",
        church: "",
        contact: "",
        email: "",
        pastor_name: "",
        is_active: "",
        view_type:"",
        user_id:1,
        
        
      },



    //Errors  
      denominationErr: "",
      churchErr: "",
      contactErr: "",
      emailErr: "",
      pastor_nameErr: "",
      is_activeErr: "",




      // bindProps:{
      //   preferredCountries: ["GH", "US","CA","GB","NG"],
      //   name: "telephone",
      //     mode: "international",
      //     defaultCountry: "GH",
      //      dropdownOptions: {
      //     showSearchBox: true,
      //     showFlags:true,
      //   },
      // }



    };
  },


      methods: {

          ...mapActions({
            search_dependent: 'dependent/'+SEARCH_DEPENDENT,
            link_dependent_support: 'dependent/'+DEPENDENT_LINK_REG,
            edit_link_dependent_support:'dependent/'+EDIT_DEPENDENT_LINK_REG
          }),




         ...mapMutations({
                 reset_dependent_search_result: 'dependent/'+RESET_DEPENDENT_SEARCH_RESULT,
                 showLoading:LOADING,
         }),







       async reg(){
      
      //notificationBox.successAlert("Registration Successful","An Email Has Been Sent To You. Please Click On The Link To Verify Your Account") 
       //console.log(this.formData);
       // console.log(this.errorClient);
        this.errorServers = [];
       this.errorClient = [];
       this.showLoading(true);

        this.denominationVal(this.formData.denomination_id);
        this.churchVal(this.formData.church);
        this.contactVal(this.formData.contact);
        this.emailVal(this.formData.email);
        this.pastor_nameVal(this.formData.pastor_name);
        this.is_activeVal(this.formData.is_active);


   

             if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }

   
   else
{


    if(this.church_view_type=="add"){


         try{
            const response = await axios.post("churches",this.formData);
              if(response.status == 200)
              {
                   this.showLoading(false);

                     this.clearFormData();
        notificationBox.succNotifications("Church Added Successfully");
        this.$emit("churchAdded");
              }


         }catch(error){

                // console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");

                  if(this.errorServers.length > 0)
       {
          
            this.getServerError();
     
       }

         }
 



    }
    else if(this.church_view_type=="edit")
    {





         try{
            const response = await axios.patch(`churches/${this.formData.church_id}`,this.formData);
              if(response.status == 200)
              {

                     this.showLoading(false);
                     this.$refs.church_modal_ref.click();
                     this.clearFormData();
        notificationBox.succNotifications("Church Added Successfully");
        this.$emit("churchAdded");
              }


         }catch(error){

                // console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");

                  if(this.errorServers.length > 0)
       {
          
            this.getServerError();
     
       }

         }
     

           


    }



}
    
    },





     clearFormData(){
        this.errorServers = [];
        this.errorClient = [];
      //this.clear_user = false;
      this.formData.denomination = "";
      this.formData.denomination_id = "";
      this.formData.church = "";
      this.formData.church_id = "";
      this.formData.contact = "";
      this.formData.email = "";
      this.formData.pastor_name = "";
      this.formData.is_active = "";
      this.denominationErr = "";
      this.churchErr = "";
      this.contactErr = "";
      this.emailErr = "";
      this.pastor_nameErr = "";
      this.is_activeErr = "";

      this.getPortalData();

    },




   getServerError(){

       this.denominationErr = 'denomination_id' in this.errorServers[0] ? this.errorServers[0].denomination_id[0] : '';
       this.churchErr = 'church' in this.errorServers[0] ? this.errorServers[0].church[0] : '';
       this.contactErr = 'contact' in this.errorServers[0] ? this.errorServers[0].contact[0] : '';
       this.emailErr = 'email' in this.errorServers[0] ? this.errorServers[0].email[0] : '';
       this.pastor_nameErr = 'pastor_name' in this.errorServers[0] ? this.errorServers[0].pastor_name[0] : '';
      this.is_activeErr = 'is_active' in this.errorServers[0] ? this.errorServers[0].is_active[0] : '';


     
           return true;

   },





    denominationVal(value){

             let field = "Denomination";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(FieldValidateService.reqVal2(value,field)){
        this.denominationErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.denominationErr);
      }else{
        if(this.errorClient.includes(this.denominationErr))
        {
        let pos = this.errorClient.indexOf(this.denominationErr);
        this.errorClient.splice(pos,1);
        }
        this.denominationErr =""; 
      }

    },






         churchVal(value){

      let max = 500;
      let field = "Church"

         if(FieldValidateService.reqMaxStringVal(value, field,max))
     {
       this.churchErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.churchErr);
     }else{

           let pos = this.errorClient.indexOf(this.fullnameErr);
          
           this.errorClient.splice(pos,1);
          this.errorClient.pop(this.churchErr);
           this.churchErr =""; 

     }


    },






     contactVal(){

            let field = "Contact"

           let value = this.formData.contact;

         if(FieldValidateService.reqValNum(value, field))
     {
       this.contactErr = FieldValidateService.reqValNum(value, field);
        this.errorClient.push(this.contactErr);
     }else{

          if(this.errorClient.includes(this.contactErr))
        {
        let pos = this.errorClient.indexOf(this.contactErr);
        this.errorClient.splice(pos,1);
        }
           this.contactErr =""; 


     }

    },






     emailVal(value){

//console.log(value);
                 let field = "Email"

         if(value != null && FieldValidateService.onlyEmail(value, field))
     {
       this.emailErr = FieldValidateService.onlyEmail(value,field);
        this.errorClient.push(this.emailErr);
     }else{

          if(this.errorClient.includes(this.contactErr))
        {
        let pos = this.errorClient.indexOf(this.contactErr);
        this.errorClient.splice(pos,1);
        }
           this.emailErr =""; 
         

     }


    },





    
      pastor_nameVal(value){

      let max = 500;
      let field = "Pastor's Name"

         if(FieldValidateService.reqMaxStringVal(value, field,max))
     {
       this.pastor_nameErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.pastor_nameErr);
     }else{

           this.pastor_nameErr =""; 
          this.errorClient.pop(this.pastor_nameErr);

     }


    },






     is_activeVal(value) {

      let max = 10;
      let field = "Is Active";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.is_activeErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.is_activeErr);
     }else{

           if(this.errorClient.includes(this.is_activeErr))
           {
            let pos = this.errorClient.indexOf(this.is_activeErr);
           this.errorClient.splice(pos,1);
            this.is_activeErr =""; 
           }
          
          

     }
    },





  getPortalData(){
      if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
           this.app_portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;
           this.formData.portal = this.app_portal;
          
            if(this.app_portal == "CHURCH")
 {
this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church_id;
this.formData.church = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church;
 }
  else if(this.app_portal == "DENOMINATION")
 {
  this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination_id;
  this.formData.denomination = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination;

  // console.log(this.formData.denomination_id)
 }
         }



         else
         {
           this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
           this.formData.portal = this.app_portal;
            if(this.app_portal == "CHURCH")
 {
this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_church_id;
this.formData.church = JSON.parse(localStorage.getItem("user_portal_info")).portal_church;
 }
  else if(this.app_portal == "DENOMINATION")
 {
  this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
  this.formData.denomination = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination;
 }
         }

  }







    




      





  

      },

      
  computed:{


    ...mapState('dependent',['dependent_view_type','dependent_info','search_message','search_dependent_result']),
    ...mapState('dependent_support',['dependent_support_view_type','dependent_support_info']),

    // firstName(){
    //   return this.checked_user_data.fullname;
    // }
  },  

    components: {
      //SpinnerComp,
       vSelect
      //VueMultiselect
     // Select2
    },

    watch:{

 


   




        church_view_type(value){
          
           if(value == "add"){
        
           this.clearFormData();



           }
        },

        church_data(value){
       
         let data = value;
            //console.log(data);
            this.formData.denomination_id = data.denomination_id;
            this.formData.denomination = data.denomination;
            this.formData.church_id = data.id;
            this.formData.church = data.church;
            this.formData.contact = data.contact;
            this.formData.email = data.email;
            this.formData.pastor_name = data.pastor_name;
            this.formData.is_active = data.is_active;
        
       
          
          
        },


    



    }
        
    }
</script>

<style scoped>



</style>