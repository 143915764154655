<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Churches" pageDesc="list of all churches"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                            <!-- <div class="col-1 text-end">
                                        <div class="d-flex align-items-center ml-4" style="min-width: 100px; float:right;">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>Per Page</strong></label>
                                            <select v-model.number.trim="perPage" class="form-control form-control-sm">
                                                <option value="20">20</option>
                                                <option value="2">2</option>
                                                <option value="40">40</option>
                                                <option value="60">60</option>
                                                <option value="80">80</option>
                                                <option value="100">100</option>
                                                <option value="120">120</option>
                                                <option value="140">140</option>
                                             </select>
                                       
                                        </div>
                                    </div> -->

                                    <PerPageComponent @perpageValue="perpageval" />

                                    <TableFilter @statusValue="statusval" portall="" />

                                        <!-- <div class="col-3 text-end">
                                         <div class="d-flex align-items-center ml-4">
                                            <label for="paginate" class="text-nowrap mr-2 mb-0"><strong>FilterBy Status</strong></label>
                                            <select v-model="status" class="form-control form-control-sm">
                                                <option value="">All Status</option>
                                                <option value="PENDING">PENDING</option>
                                                <option value="APPROVED">APPROVED</option>
                                                <option value="REJECTED">REJECTED</option>
                                                <option value="BAN">BAN</option>
                                            </select>

                                         </div>
                                    </div> -->
                                



                                    <!-- <div class="col-4 text-end">
                                        <div style="min-width: 400px; float:right;">
                                                <div class="input-group mb-3">
                                                <input type="text" class="form-control" v-model="search" placeholder="Search" name="SearchTerm">
                                                </div>
                                        </div>
                                    </div> -->

                                    <TableSearch @searchValue="searchval" />


                                  
                                     <!-- <div class="col-2 text-end">
                          
                                        <div class="dropdown ml-4">
                                            <button v-if="checked.length > 0" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                                With checked ({{ checked.length }})
                                            </button>
                                            <div class="dropdown-menu">
                                                <a  onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" href="#" class="dropdown-item" type="button" @click.prevent="deleteRecords">
                                                    Delete
                                                </a>
                                                   <a :href="url"  class="dropdown-item" type="button">
                                                    Export
                                                </a>
                                            </div>
                                        </div>
                                    </div> -->


                                    <ActionButton @deleteRecords="deleteRecords" :num_checked="checked.length" portall="CHURCH" />









                                
                                    <div class="col-2 text-end">
                                        <!-- <a class="btn btn-outline-success" @click="userAdd()" data-bs-toggle="modal"  href="#userModal">
                                            <i class="fas fa-plus"></i> Add User
                                        </a>    -->


                                                   
                                        <a class="btn btn-outline-success" @click="churchAdd()" data-bs-toggle="modal"  href="#churchModal" v-if="app_portal=='MAIN' || app_portal=='DENOMINATION'">
                                            <i class="fas fa-plus"></i> Add Church
                                        </a>   


                                           <a style="display:none" class="btn btn-outline-success" ref="church_ref" data-bs-toggle="modal"  href="#churchModal">
                                            <i class="fas fa-plus"></i> Church Ref
                                        </a>   

                              
                                    </div>




                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" />


                                </div>
                            </div>
                            
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <div class="table-responsive table-responsive-data2">
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col" v-if="app_portal=='MAIN' || app_portal=='DENOMINATION'">
                                                <a href="#" @click.prevent="change_sort('churches.church')">Church</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='churches.church'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='churches.church'">&darr;</span>
                                                </th>


                                                <th scope="col" v-if="app_portal=='MAIN'">
                                                <a href="#" @click.prevent="change_sort('denominations.denomination')">Denomination</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='denominations.denomination'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='denomination.denomination'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('churches.contact')">Contact</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='churches.contact'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='churches.contact'">&darr;</span>
                                                </th>


                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('churches.email')">Email</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='churches.email'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='churches.email'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('churches.pastor_name')">Pastor's Name</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='churches.pastor_name'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='churches.pastor_name'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('churches.is_active')">Is Active</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='churches.is_active'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='churches.is_active'">&darr;</span>
                                                </th>

                                     

                                               


                                                <th scope="col">
                                                Action
                                                </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(chu) in churches" :key="chu.id" :class="isChecked(chu.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="chu.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td v-if="app_portal=='MAIN' || app_portal=='DENOMINATION'">{{chu.church}}</td>
                                                    <td v-if="app_portal=='MAIN'">{{chu.denomination}}</td>
                                                    <td>{{chu.contact}}</td>
                                                    <td>{{chu.email}}</td>
                                                    <td>{{chu.pastor_name}}</td>
                                                    <td>{{chu.is_active }}</td>
                                                    <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="churchEdit(chu.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(chu.id)" v-if="app_portal=='MAIN' || app_portal=='DENOMINATION'">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="churchView(chu.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div> 

  

        <ChurchModals @churchAdded="churchAdded" :church_view_type="view_type" :church_data="getChurchData"  />

        <!-- <UserModals @userAdded="userAdded"  @provider_view_typee="provider_view_typee"   @country_view_typee="country_view_typee"  :getUserPortalData="getUserPortalData"   :view_type="view_type"  />

        <EmployeeUserCountry @userView="userView" :employee_country_view_type="country_view_type" :employee_user_id="employee_user_id"  />    
  
        <EmployeeUserProvider @userView="userView"   :employee_provider_view_type="provider_view_type" :employee_provider_user_id="employee_provider_user_id" />      -->
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
import PerPageComponent from "@/components/table/PerPage.vue"
import TableSearch from "@/components/table/TableSearch"
import TableFilter from "@/components/table/TableFilter"
import ActionButton from "@/components/table/ActionButton"
import SelectAllComponent from "@/components/table/SelectAllComponent"
import ChurchModals from "@/components/modalForms/Church.vue"
import axios from "axios"

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import {  mapMutations, mapState } from 'vuex'
import { LOADING, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants'


export default {
  name: "ChurchView",
  beforeRouteEnter(to,from,next){


     let user_level = "";
     if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
     const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
     user_level = user_data[0].user_level;
     }
     else{
             const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
             user_level = user_data.user_level;
     }
   
    
    if(user_level =='SUPER ADMIN' || user_level =='DENOMINATION ADMIN' || user_level =='CHURCH ADMIN'){
        next();

    }else{
  
        next("/user-not-authorise"); 
    }
 
 
    
  },
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    PerPageComponent,
    TableSearch,
    TableFilter,
    ActionButton,
    SelectAllComponent,
    VueTailwindPagination,
    ChurchModals

  },



  data(){
    return {
        churches:{},
        //showUserModals:false,

        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        is_active: "",
        checked:[],
        user_table_data:{},
        user_portal_table_data:{},
        getChurchData:{},
        view_type:"",
        app_portal:"",
        // provider_id:"",
        // country_view_type:"",
        // provider_view_type:"", 
        // employee_user_id:"",
        // employee_provider_user_id:"",
        // support_id:"",
        // dependent_id:"",
        // getSupportData:{},

        denomination_id:"",
        church_id:"",
        login_user_id:"",
        user_level:"",
        selectPage:false,
        //selectAll:false,
     //   sort_direction: 'desc',
     //   sort_field: 'fullname',
      //  url:'',

    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    //...mapState("user",['search_user_id_result'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),




    selectAllRecords(){

        axios.get('churches/all?is_active=' + this.getstatus + '&portal='+this.app_portal + '&denomination_id='+this.denomination_id+'&church_id='+this.church_id)
             .then(response => {
                 console.log(response.data);
                  this.checked = response.data;
                  // this.selectAll = true;
                 // this.table_checked(response.data);
                   this.table_select_all(true);
             });
    },



    change_sort(field){

        this.table_sort_field_direction(field);
        this.getChurch();

    },



   deleteSingleRecord(id){

    //console.log(id);
        this.showLoading(true);
       axios.delete(`churches/${id}`)
       .then( () => {
        this.checked = this.checked.filter(church_id => church_id != id)
        //this.table_checked(this.checked.filter(user_portal_id => user_portal_id != id))
        //console.log(response.data.success)
         this.showLoading(false);
        notificationBox.succNotifications("Deleted Successfully");
         this.getChurch();
       }).catch(error => {
        //console.log(error.message)
         this.showLoading(false);
         notificationBox.errorNotifications(error.response.data.error)
       })


   },



   deleteRecords(){
    this.showLoading(true);
      axios.delete(`churches/massDestroy/${this.checked}`)
      .then((response) => {
         if(response.status == 200){
            //console.log(response)
            this.showLoading(false);
            notificationBox.succNotifications(response.data.success);
            this.checked = [];
            //this.table_checked([])
            this.getChurch();

         }
      }).catch(error => {
       // console.log(error);
       this.showLoading(false);
         notificationBox.errorNotifications(error.response.data.error);
       })
   },





   


    pageChange(pageNumber){

        //this.currentPage = pageNumber;
        this.table_current_page(pageNumber);
        this.getChurch();

    },




   isChecked(id){

    return  this.checked.includes(id);

   },



    getChurch(){
   
        axios.get(
            'churches?page=' + this.currentPage + 
            '&page_size=' + this.perpage + 
            '&q=' +this.getsearch + 
            '&is_active=' + this.getstatus +
            '&sort_direction=' + this.sort_direction +
            '&sort_field=' + this.sort_field +
             '&portal='+this.app_portal + 
             '&denomination_id='+this.denomination_id+
             '&church_id='+this.church_id
            )
        .then(response => {

            this.churches = response.data.data.data;
            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)
        });

    },



    perpageval(value){
        this.table_per_page(value);
       this.getChurch();

    },


    // banSucc(){

    //     this.getSupportMember();

    // },


    searchval(value){

         this.table_search(value);
        this.getChurch();

    },

    statusval(value){

        this.table_status(value);
        this.getChurch();

    },

 

   
   churchEdit(church_id){

    this.church_id = church_id;
    this.view_type = "edit";
    this.getChurchDataa(church_id);
    this.$refs.church_ref.click();

   },




    churchView(church_id){

    this.church_id = church_id;
    this.view_type = "view";
    this.getChurchDataa(church_id);
    
    this.$refs.church_ref.click();

   },




      async getChurchDataa(church_id){
         
             this.showLoading(true);
             try{
            //   console.log("From Watchers",user_portal_id);
            //   console.log("From Props",this.get_user_portal_id);
            
            const response = await axios.get(`churches/${church_id}`);
            this.showLoading(false);
                this.getChurchData = response.data.data;
             }catch(error){
                this.showLoading(false);
             }
          
              },





    churchAdd(){

        this.view_type = "add";

     },






     churchAdded(){
        this.getChurch();
     },




  
  },





 async created(){


       
         if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
                  this.app_portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;
                this.denomination_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination_id;
                this.church_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church_id;
         }
         else
         {
       this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
this.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
this.church_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_church_id;
//console.log(JSON.parse(localStorage.getItem("user_portal_info")))
         }
      


    //this.currentPage = 1;
    this.table_current_page(1);
    this.getChurch();
  },


  watch:{
    // "perPage"(value){
    //    this.table_per_page(value);
    //    this.getUser();
    // },
 
    //  "search"(value){
    //     this.table_search(value);
    //     this.getUser();
    //  },

    //  "status"(value){
    //     this.table_status(value);
    //     this.getUser();
    //  },

     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const churches = JSON.parse(JSON.stringify(this.churches));
            churches.forEach(church=>{
                this.checked.push(church.id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "churches/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>